import { RISK_CATEGORY } from "../../constants/RiskCategory";

export const getRiskColor = (value) => {
	if (value >= 0 && value <= 20) {
		return `bg-low-risk`;
	} else if (value > 20 && value <= 40) {
		return `bg-medium-risk`;
	} else if (value > 40 && value <= 60) {
		return `bg-medium-high-risk`;
	} else if (value > 60 && value <= 80) {
		return `bg-high-risk`;
	} else if (value > 80 && value <= 100) {
		return `bg-very-high-risk`;
	} else {
		return "";
	}
};

export const getRiskCategory = (value) => {
	if (value >= 0 && value <= 20) {
		return RISK_CATEGORY.Low;
	} else if (value > 20 && value <= 40) {
		return RISK_CATEGORY.Medium;
	} else if (value > 40 && value <= 60) {
		return RISK_CATEGORY.MediumHigh;
	} else if (value > 60 && value <= 80) {
		return RISK_CATEGORY.High;
	} else if (value > 80 && value <= 100) {
		return RISK_CATEGORY.VeryHigh;
	}
};

export const getRiskColorWithCategory = (value) => {
	if (value === RISK_CATEGORY.Low) {
		return `bg-low-risk`;
	} else if (value === RISK_CATEGORY.Medium) {
		return `bg-medium-risk`;
	} else if (value === RISK_CATEGORY.MediumHigh) {
		return `bg-medium-high-risk`;
	} else if (value === RISK_CATEGORY.High) {
		return `bg-high-risk`;
	} else if (value === RISK_CATEGORY.VeryHigh) {
		return `bg-very-high-risk`;
	} else {
		return "";
	}
};

export const getRiskBorderColorWithCategory = (value) => {
	if (value === RISK_CATEGORY.Low) {
		return `border-low-risk`;
	} else if (value === RISK_CATEGORY.Medium) {
		return `border-medium-risk`;
	} else if (value === RISK_CATEGORY.MediumHigh) {
		return `border-medium-high-risk`;
	} else if (value === RISK_CATEGORY.High) {
		return `border-high-risk`;
	} else if (value === RISK_CATEGORY.VeryHigh) {
		return `border-very-high-risk`;
	} else {
		return "";
	}
};

export const calculateClimateRiskWeightedAverages = (dataArray) => {
	const indices = [
		"Drought",
		"Heat Stress",
		"Extreme Precipitation",
		"Water Stress",
		"Fluvial Flood",
		"Pluvial Flood",
	];

	// Calculate total exposure
	const totalExposure = dataArray.reduce((total, locationData) => {
		return total + locationData.exposure;
	}, 0);

	// Initialize an object to hold the sums of each index
	const weightedSums = indices.reduce((acc, index) => {
		if (dataArray[0].data[index]) {
			acc[index] = dataArray[0].data[index].map((_, i) => {
				return dataArray.reduce((sum, locationData) => {
					// If the value is "-", skip it and return the sum
					const value = locationData.data[index]?.[i];
					return value === "-"
						? sum
						: sum + (value || 0) * locationData.exposure;
				}, 0);
			});
		}
		return acc;
	}, {});

	// Calculate the weighted averages for each index
	const weightedAverages = indices.reduce((acc, index) => {
		if (weightedSums[index]) {
			acc[index] = weightedSums[index].map((weightedSum, i) => {
				// If any data point in the array is "-", set the result to "-"
				const hasDash = dataArray.some(
					(locationData) => locationData.data[index]?.[i] === "-"
				);
				return hasDash
					? "-"
					: parseFloat((weightedSum / totalExposure).toFixed(3));
			});
		}
		return acc;
	}, {});

	// Years are consistent across locations, use the years from the first location
	const years = dataArray[0].years;

	return {
		data: weightedAverages,
		years: years,
	};
};

export const getMaxRiskCategoriesOfAssets = (data) => {
	const excludeKeys = ["asset", "latlon", "location"];
	const isFutureData = () =>
		data.length > 0 && data[0].data && typeof data[0].data === "object";

	const RISK_PRIORITY = Object.values(RISK_CATEGORY).reverse();

	return data.map((item) => {
		const categories = {};

		if (isFutureData()) {
			Object.keys(item.data).forEach((key) => {
				if (item.data[key]) {
					item.data[key].forEach((value) => {
						if (value !== null) {
							const category = getRiskCategory(value);
							categories[category] =
								(categories[category] || 0) + 1;
						}
					});
				}
			});
		} else {
			Object.keys(item).forEach((key) => {
				if (!excludeKeys.includes(key) && item[key]) {
					const category = getRiskCategory(item[key]);
					categories[category] = (categories[category] || 0) + 1;
				}
			});
		}

		// Check categories based on priority order
		for (const priorityCategory of RISK_PRIORITY) {
			if (categories[priorityCategory]) {
				return priorityCategory; // Return the first highest priority category found
			}
		}

		return null; // In case no category was found
	});
};

export const calculateAndAddMaxPerilBaseline = (data, isFinancial = false) => {
	const result = data.map((item) => {
		const perilValues = [
			item.Drought,
			...(isFinancial ? [] : [item["Extreme Precipitation"]]),
			item["Fluvial Flood"],
			item["Heat Stress"],
			item["Pluvial Flood"],
			item["Water Stress"],
		];

		// Find the maximum value among all peril values
		const maxPeril = Math.max(...perilValues);

		return {
			...item,
			maxPeril: maxPeril,
		};
	});
	return result;
};

export const calculateAndAddMaxPerilProjected = (data) => {
	const processAsset = (asset) => {
		const { data: perilData, years } = asset;
		const yearCount = years.length;

		// Initialize maxPerils array with default values
		const maxPerils = Array(yearCount).fill(0);

		// Iterate over each peril type and update maxPerils
		for (const peril in perilData) {
			perilData[peril].forEach((value, index) => {
				if (value !== "-" && parseFloat(value) > maxPerils[index]) {
					maxPerils[index] = parseFloat(value);
				}
			});
		}

		return {
			...asset,
			data: {
				...perilData,
				maxPerils,
			},
		};
	};

	if (Array.isArray(data)) {
		return data.map(processAsset);
	} else if (typeof data === "object" && data !== null) {
		return processAsset(data);
	} else {
		throw new TypeError("Input data must be an array or an object");
	}
};
