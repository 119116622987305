export const defaultTermOptions = [
	{
		label: "Baseline",
		value: "Baseline",
	},
	{
		label: "Projections",
		value: "Projections",
	},
];

export const sspTermOptions = [
	{
		label: "Projections",
		value: "Projections",
	},
];

export const baselineScenarioOptions = [
	{
		label: "NA",
		value: "NA",
	},
];

export const projectionScenarioOptions = [
	{
		label: "ssp585",
		value: "ssp585",
	},
	{
		label: "ssp245",
		value: "ssp245",
	},
];

export const chartsOptions = [
	{
		label: "Rainfall - Max 1D, 5D",
		value: "Rainfall - Max 1D, 5D",
		indexName: "Extreme Precipitation",
		legend: [
			{
				title: "Max 1 day rain (mm):",
				body: "The highest rainfall in a single day in a year.",
			},
			{
				title: "Max 5 day rain (mm):",
				body: "The highest total rainfall over any 5 consecutive days in a year.",
			},
		],
	},
	{
		label: "Rainfall - Extreme Precipitation (95th percentile)",
		value: "Rainfall - Extreme Precipitation (95th percentile)",
		indexName: "Extreme Precipitation",
		legend: [
			{
				title: "Extreme Precipitation Days (95th percentile):",
				body: "The number of days when rainfall exceeds the 95th percentile of historical daily rainfall.",
			},
		],
	},
	{
		label: "Heat Stress",
		value: "Heat Stress",
		indexName: "Heat Stress",
		legend: [
			{
				title: "Heat Index Days:",
				body: "Days when a combination of heat and humidity creates high perceived temperature stress.",
			},
			{
				title: "Extreme Heat Days (90th percentile):",
				body: "Days when the maximum temperature exceeds the 90th percentile of historical daily highs.",
			},
		],
	},
	{
		label: "Heat - Productivity loss",
		value: "Heat - Productivity loss",
		indexName: "Productivity Loss",
		legend: [
			{
				title: "Productivity loss (%):",
				body: "Productivity loss measures the effects of temperature, humidity, and other environmental factors on human work capacity depending upon the industry/sector.",
			},
		],
	},
	{
		label: "Heat - Additional CDD",
		value: "Heat - Additional CDD",
		indexName: "Additional CDD",
		legend: [
			{
				title: "Additional CDD (days):",
				body: "Additional Cooling Degree Days (CDD) indicates increase of energy required for cooling as compared to the long term historical average.",
			},
		],
	},
];

export const analyticsOptions = [
	{
		label: "Hazard & Vulnerability Charts",
		value: 0,
	},
	{
		label: "Financial Metrics Charts",
		value: 1,
	},
];
