import React, { useState, useEffect, useContext } from "react";
import Loader from "../../components/Loading/Loader";
import { LoginContext } from "../../LoginProvider/LoginProvider";
import ChangePassowrdModal from "../../components/Modal/ChangePasswordModal";
import SuccessModal from "../../components/Modal/SuccessModal";
import ErrorModal from "../../components/Modal/ErrorModal";
import PhysicalClimateRiskScoreCurrent from "./components/PhysicalClimateRiskScoreCurrent";
import ClimateFinancialRiskScoreCurrent from "./components/ClimateFinancialRiskScoreCurrent";
import ClimateVulnerabilityScoreCurrent from "./components/ClimateVulnerabilityScoreCurrent";
import { CURRENT_TAB_NAMES } from "../../constants/tabNames";
import NewsSearchModal from "./components/Modal/NewsSearchModal";
import { useNavigate } from "react-router-dom";

const CurrentAssessment = () => {
	const path = useNavigate();
	const [loading, setLoading] = useState(false);
	const [activeTab, setActiveTab] = useState("Climate Hazard Score");
	const [changePasswordModal, setChangePasswordModal] = useState(false);
	const [selectedNewsModal, setSelectedNewsModal] = useState(false);
	const [successmsg, setSuccessmsg] = useState({
		status: false,
		msg: "",
	});
	const [errormsg, setErrormsg] = useState({
		status: false,
		msg: "",
	});

	const { userData, login } = useContext(LoginContext);

	useEffect(() => {
		setChangePasswordModal(userData?.isNewUser);
	}, [userData]);

	const handleTabClick = (tab) => {
		setActiveTab(tab);
	};

	return (
		<div className={`mb-8 -mx-4 ${loading ? "relative h-[80vh]" : ""}`}>
			{loading && <Loader />}
			{selectedNewsModal && (
				<NewsSearchModal
					isSelected={selectedNewsModal}
					setIsSelected={setSelectedNewsModal}
				/>
			)}
			<SuccessModal
				successmsg={successmsg}
				setSuccessmsg={setSuccessmsg}
			/>
			<ErrorModal errormsg={errormsg} setErrormsg={setErrormsg} />
			{changePasswordModal && (
				<ChangePassowrdModal
					userData={userData}
					login={login}
					isSelected={changePasswordModal}
					setIsSelected={setChangePasswordModal}
					setSuccessmsg={setSuccessmsg}
					setErrormsg={setErrormsg}
				/>
			)}
			<div className={`${loading ? "blur-sm" : ""}`}>
				<div className="flex items-center justify-between mb-2 pl-2 md:pl-1 lg:pl-2 xl:pl-2 sticky top-0 z-20 bg-[#f7f8f9]">
					<h2 className="text-left text-2xl lg:mb-8 xl:mb-10 xl:pt-1 pl-2 font-semibold">
						Baseline
					</h2>
					<div>
						<button
							className={
								"mr-4 lg:mb-8 xl:mb-10 xl:mt-1 py-2 shadow-2xl relative px-8 bg-gradient-to-r from-[#4B74FF] to-[#9281FF] hover:from-[#9281FF] hover:to-[#4B74FF] capitalize font-medium border-none text-white rounded-3xl focus:outline-[#9281FF]"
							}
							onClick={() => {
								path("/user/analytics");
							}}
						>
							Analytics
						</button>
						<button
							className={
								"mr-4 lg:mb-8 xl:mb-10 xl:mt-1 py-2 shadow-2xl relative px-8 bg-gradient-to-r from-[#4B74FF] to-[#9281FF] hover:from-[#9281FF] hover:to-[#4B74FF] capitalize font-medium border-none text-white rounded-3xl focus:outline-[#9281FF]"
							}
							onClick={() => {
								setSelectedNewsModal(true);
							}}
						>
							Climate News Search
						</button>
					</div>
				</div>
				<div className="w-full mx-auto mt-2 rounded-md">
					<nav className="mx-4 box-shadow5 flex items-center  text-[#fff]  viewPool-navbar sticky top-14 z-20 bg-[#f7f8f9]">
						<ul className="flex  w-full ">
							<li
								className={`w-1/3 cursor-default ${
									activeTab ===
									CURRENT_TAB_NAMES.ClimateHazardScore
										? "active"
										: "inactive"
								}`}
								onClick={() =>
									handleTabClick(
										CURRENT_TAB_NAMES.ClimateHazardScore
									)
								}
							>
								<span>Climate Hazard Score</span>
							</li>
							<li
								className={`w-1/3 cursor-default ${
									activeTab ===
									CURRENT_TAB_NAMES.PotentialEconomicLoss
										? "active"
										: "inactive"
								}`}
								onClick={() =>
									handleTabClick(
										CURRENT_TAB_NAMES.PotentialEconomicLoss
									)
								}
							>
								<span>Socio-Economic Vulnerability Score</span>
							</li>
							<li
								className={`w-1/3 cursor-default ${
									activeTab === CURRENT_TAB_NAMES.CFRScore
										? "active"
										: "inactive"
								}`}
								onClick={() =>
									handleTabClick(CURRENT_TAB_NAMES.CFRScore)
								}
							>
								<span>Climate Financial Risk Score</span>
							</li>
						</ul>
					</nav>
					<div className="text-slate-800">
						{activeTab === CURRENT_TAB_NAMES.ClimateHazardScore && (
							<PhysicalClimateRiskScoreCurrent
								setLoading={setLoading}
							/>
						)}
						{activeTab ===
							CURRENT_TAB_NAMES.PotentialEconomicLoss && (
							<ClimateVulnerabilityScoreCurrent
								setLoading={setLoading}
							/>
						)}
						{activeTab === CURRENT_TAB_NAMES.CFRScore && (
							<ClimateFinancialRiskScoreCurrent
								setLoading={setLoading}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CurrentAssessment;
