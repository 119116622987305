export function getOnlyErrorText(str) {
	return str.substring(str.indexOf(":") + 1);
}

export function formatNumberWithCommas(number) {
	var fNum = parseFloat(number).toFixed(2);
	// Convert number to string
	let numStr = String(fNum);

	// Split the string into integer and decimal parts
	let parts = numStr.split(".");
	let integerPart = parts[0];
	let decimalPart = parts.length > 1 && parts[1] > 0 ? "." + parts[1] : "";

	// Add commas to the integer part
	let integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

	// Concatenate integer and decimal parts
	return integerWithCommas + decimalPart;
}

export function formatNumberForINR(number) {
	var fNum = parseFloat(number);

	// Convert number to string
	let numStr = String(Math.floor(fNum));

	// Split the string into integer and decimal parts
	let parts = fNum.toString().split(".");
	let integerPart = parts[0];
	let decimalPart = parts[1] ? "." + parts[1] : "";

	// Add commas to the integer part (for INR format)
	let lastThreeDigits = integerPart.slice(-3);
	let restOfDigits = integerPart.slice(0, -3);

	let integerWithCommas =
		restOfDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
		(restOfDigits ? "," : "") +
		lastThreeDigits;

	// Concatenate integer and decimal parts, omitting decimal if there is none
	return decimalPart ? integerWithCommas + decimalPart : integerWithCommas;
}

export function roundNumber(num, decimalPlaces = 0) {
	// Check if the number is a float
	if (Number(num) === num && num % 1 !== 0) {
		// If it's a float, round to decimal places
		return parseFloat(num.toFixed(decimalPlaces));
	}
	// If it's an integer, return it unchanged
	return num;
}
