import React, { useContext, useEffect, useState } from "react";
import Map from "../../../components/Map/Map";
import {
	calculateAndAddMaxPerilBaseline,
	getMaxRiskCategoriesOfAssets,
	getRiskColor,
} from "../../../services/Helpers/climateRiskHelper";
import CurrentClimateRiskTable from "./Tables/CurrentClimateRiskTable";
import { getClimateRiskScore } from "../../../services/ApiCalls/climateRiskCall";
import { LoginContext } from "../../../LoginProvider/LoginProvider";
import { getLocationsFromAssets } from "../../../services/Helpers/locationHelper";
import PortfolioTable from "./Tables/PortfolioTable";

const PhysicalClimateRiskScoreCurrent = ({ setLoading }) => {
	const [tableData, setTableData] = useState([]);
	const [locations, setLocations] = useState();
	const { userData } = useContext(LoginContext);

	useEffect(() => {
		(async () => {
			setLoading(true);
			const result = await getClimateRiskScore(userData?.id);
			if (result?.status === "success") {
				let score = result.data;
				if (userData?.assets) {
					const maxRisks = getMaxRiskCategoriesOfAssets(score);
					const locationsData = getLocationsFromAssets(
						userData?.assets,
						maxRisks
					);
					setLocations(locationsData);
				}
				score = calculateAndAddMaxPerilBaseline(score);
				setTableData(score);
			}
			setLoading(false);
		})();
	}, [userData]);

	return (
		<div>
			<section className="text-gray-600  px-4 pb-4">
				<div className="w-full  mx-auto bg-white shadow-lg rounded-md border border-gray-200">
					<div className="p-5">
						<div className="flex justify-center mt-4">
							<div className="h-[400px] w-full bg-white shadow-lg rounded-md border border-gray-200 my-4 mb-8 text-center relative">
								<Map data={locations} />
							</div>
						</div>
						<div className="text-lg font-bold mb-0 pb-0">
							Portfolio Heat Map
						</div>
						<div>
							Number/percentage of assets in the portfolio exposed
							to various levels of Climate Hazards
						</div>
						<div className="overflow-x-auto mb-8">
							<PortfolioTable tableData={tableData} />
						</div>
						<div className="text-lg font-bold mt-6 mb-4">
							Asset-wise Hazard Score
						</div>
						<div className="overflow-x-auto">
							<CurrentClimateRiskTable
								tableData={tableData}
								getRiskColor={getRiskColor}
							/>
							<br />
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default PhysicalClimateRiskScoreCurrent;
