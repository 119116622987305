import { computeTrendline } from "./chartHelper";

export const getMax1d5dConfig = (data, years) => {
	const date_1d_max = data.date_1d_max_preci_map.map(
		(item) => item.max_preci
	);
	const date_5d_max = data.date_5d_max_preci_map.map(
		(item) => item.max_preci
	);
	const trendline1dMax = computeTrendline(years, date_1d_max);
	const trendline5dMax = computeTrendline(years, date_5d_max);
	const config = [
		{
			x: years,
			y: date_1d_max,
			type: "scatter",
			mode: "lines+markers",
			name: "Max 1 day rain",
			line: { shape: "spline" },
			marker: { size: 6, color: "#1f77b4" },
		},
		{
			x: years,
			y: trendline1dMax,
			type: "scatter",
			mode: "lines",
			name: "Max 1 day rain Trendline",
			line: { dash: "dash", color: "#1f77b4" },
		},
		{
			x: years,
			y: date_5d_max,
			type: "scatter",
			mode: "lines+markers",
			name: "Max 5 day rain",
			line: { shape: "spline", color: "#ff7f0e" },
			marker: { size: 6 },
		},
		{
			x: years,
			y: trendline5dMax,
			type: "scatter",
			mode: "lines",
			name: "Max 5 day rain Trendline",
			line: { dash: "dash", color: "#ff7f0e" },
		},
	];
	return config;
};

export const getEpStressConfig = (data, years) => {
	const date_ep_stress = data.date_ep_stress_map.map(
		(item) => item.ep_exceed_days
	);
	const trendlineEpStress = computeTrendline(years, date_ep_stress);
	const config = [
		{
			x: years,
			y: date_ep_stress,
			type: "scatter",
			mode: "lines+markers",
			name: "Extreme Precipitation<br>Days (95th percentile)",
			line: { shape: "spline", color: "#1f77b4" },
			marker: { size: 6 },
		},
		{
			x: years,
			y: trendlineEpStress,
			type: "scatter",
			mode: "lines",
			name: "Extreme Precipitation<br>Days (95th percentile) Trendline",
			line: { dash: "dash", color: "#1f77b4" },
		},
	];
	return config;
};

export const getHeatStressConfig = (data, years) => {
	const hiDays = data.map((item) => item.a_hi_days);
	const pcDays = data.map((item) => item.c_90pcdays);

	const trendlineHiDays = computeTrendline(years, hiDays);
	const trendlinePcDays = computeTrendline(years, pcDays);
	const config = [
		{
			x: years,
			y: hiDays,
			type: "scatter",
			mode: "lines+markers",
			name: "Heat Index Days",
			line: { shape: "spline", color: "#1f77b4" },
			marker: { size: 6 },
		},
		{
			x: years,
			y: trendlineHiDays,
			type: "scatter",
			mode: "lines",
			name: "Heat Index Days Trendline",
			line: { dash: "dash", color: "#1f77b4" },
		},
		{
			x: years,
			y: pcDays,
			type: "scatter",
			mode: "lines+markers",
			name: "Extreme Heat Days (90th percentile)",
			line: { shape: "spline", color: "#ff7f0e" },
			marker: { size: 6 },
		},
		{
			x: years,
			y: trendlinePcDays,
			type: "scatter",
			mode: "lines",
			name: "Extreme Heat Days Trendline",
			line: { dash: "dash", color: "#ff7f0e" },
		},
	];
	return config;
};

export const getProductivityLossConfig = (data, years) => {
	const trendline = computeTrendline(years, data);
	const config = [
		{
			x: years,
			y: data,
			type: "scatter",
			mode: "lines+markers",
			name: "Productivity loss",
			line: { shape: "spline", color: "#1f77b4" },
			marker: { size: 6 },
		},
		{
			x: years,
			y: trendline,
			type: "scatter",
			mode: "lines",
			name: "Productivity loss Trendline",
			line: { dash: "dash", color: "#1f77b4" },
		},
	];
	return config;
};
export const getAdditionalCDDConfig = (data, years) => {
	const trendline = computeTrendline(years, data);
	const config = [
		{
			x: years,
			y: data,
			type: "scatter",
			mode: "lines+markers",
			name: "Additional CDD",
			line: { shape: "spline", color: "#1f77b4" },
			marker: { size: 6 },
		},
		{
			x: years,
			y: trendline,
			type: "scatter",
			mode: "lines",
			name: "Additional CDD Trendline",
			line: { dash: "dash", color: "#1f77b4" },
		},
	];
	return config;
};

export const getFinancialMetricsConfig = (
	assetDamage,
	businessInterruption,
	climateAnnualExpectedLosses,
	years
) => {
	const config = [
		{
			x: years,
			y: assetDamage,
			type: "scatter",
			mode: "lines+markers",
			name: "Asset Damage",
			line: { shape: "spline", color: "#1f77b4" },
			marker: { size: 6 },
		},
		{
			x: years,
			y: businessInterruption,
			type: "scatter",
			mode: "lines+markers",
			name: "Business Interruption",
			line: { shape: "spline", color: "#ff7f0e" },
			marker: { size: 6 },
		},
		{
			x: years,
			y: climateAnnualExpectedLosses,
			type: "scatter",
			mode: "lines+markers",
			name: "Climate Annual Expected Losses",
			line: { shape: "spline", color: "#5CB338" },
			marker: { size: 6 },
		},
	];
	return config;
};

export const getChartLayout = (title, xTitle, yTitle) => {
	return {
		xaxis: {
			title: xTitle,
		},
		yaxis: {
			title: yTitle,
		},
		title: {
			text: title,
			font: {
				size: 18,
			},
		},
		showlegend: true,
		hovermode: "closest",
	};
};
