import React, { useState, useEffect, useContext } from "react";
import { LoginContext } from "../../../LoginProvider/LoginProvider";
import Selection from "../../../components/Inputs/Selection";
import {
	chartsOptions,
	baselineScenarioOptions,
	projectionScenarioOptions,
	defaultTermOptions,
	sspTermOptions,
} from "../../../constants/analytics";
import { getClimateRiskChartData } from "../../../services/ApiCalls/climateRiskCall";
// import YearRangePicker from "../../../components/RangePicker/YearRangePicker";
import LineChart from "../../../components/Chart/LineChart";
import {
	getAdditionalCDDConfig,
	getChartLayout,
	getEpStressConfig,
	getHeatStressConfig,
	getMax1d5dConfig,
	getProductivityLossConfig,
} from "../../../services/Helpers/assetConfig";

const HazardVulnerabilityCharts = ({ setLoading }) => {
	const [status, setStatus] = useState("");
	const [config, setConfig] = useState([]);
	const [layout, setLayout] = useState({});
	const [title, setTitle] = useState("");
	// const [yearRange, setYearRange] = useState([]);
	const [indexName, setIndexName] = useState(chartsOptions[0].indexName);

	const [assetOptions, setAssetOptions] = useState([]);
	const [scenarioOptions, setScenarioOptions] = useState(
		baselineScenarioOptions
	);
	const [termOptions, setTermOptions] = useState(defaultTermOptions);
	const [term, setTerm] = useState(termOptions[0].value);
	const [scenario, setScenario] = useState(baselineScenarioOptions[0].value);
	const [asset, setAsset] = useState();
	const [assetName, setAssetName] = useState();
	const [location, setLocation] = useState("");
	const [chartName, setChartName] = useState(chartsOptions[0].label);
	const [chart, setChart] = useState(chartsOptions[0].value);
	const [legends, setLegends] = useState(chartsOptions[0].legend);

	const [termOutline, setTermOutline] = useState(false);
	const [scenarioOutline, setScenarioOutline] = useState(false);
	const [assetOutline, setAssetOutline] = useState(false);
	const [chartsOutline, setChartsOutline] = useState(false);
	const { userData } = useContext(LoginContext);
	useEffect(() => {
		if (userData?.assets) {
			const assetData = userData?.assets.map((asset) => {
				return {
					label: asset?.["Asset Name"],
					value: `${asset?.Lat},${asset?.Long}`,
					location: asset?.Location,
				};
			});
			const assetName = assetData?.[0]?.label;
			const location = assetData?.[0]?.location;
			setAssetOptions(assetData);
			setAsset(assetData?.[0]?.value);
			setAssetName(assetName);
			setLocation(location);
		}
	}, [userData]);

	useEffect(() => {
		(async () => {
			setLoading(true);
			const mainTitle = `${indexName}_${assetName}_${location}_${
				scenario === "NA" ? "Baseline" : scenario
			}`;
			setTitle(mainTitle);
			const result = await getClimateRiskChartData(
				userData?.id,
				indexName,
				asset,
				undefined,
				undefined,
				term === "Baseline" ? true : false,
				scenario === "ssp585" ? "SSP5-8.5" : "SSP2-4.5"
			);
			setStatus(result?.status);
			if (result?.status === "success") {
				const data = result?.data?.data;
				const years = result?.data?.years;
				let layoutData, configData;
				switch (chart) {
					case "Rainfall - Max 1D, 5D":
						layoutData = getChartLayout(
							mainTitle,
							"Years",
							"Rainfall (mm)"
						);
						setLayout(layoutData);

						configData = getMax1d5dConfig(data, years);
						setConfig(configData);
						break;
					case "Rainfall - Extreme Precipitation (95th percentile)":
						layoutData = getChartLayout(
							mainTitle,
							"Years",
							"Rainfall (days)"
						);
						setLayout(layoutData);

						configData = getEpStressConfig(data, years);
						setConfig(configData);
						break;
					case "Heat Stress":
						layoutData = getChartLayout(
							mainTitle,
							"Years",
							"Days/year"
						);
						setLayout(layoutData);

						configData = getHeatStressConfig(data, years);
						setConfig(configData);
						break;

					case "Heat - Productivity loss":
						layoutData = getChartLayout(
							mainTitle,
							"Years",
							"Productivity loss (%)"
						);
						setLayout(layoutData);

						configData = getProductivityLossConfig(data, years);
						setConfig(configData);
						break;

					case "Heat - Additional CDD":
						layoutData = getChartLayout(
							mainTitle,
							"Years",
							"Additional CDD (Days)"
						);
						setLayout(layoutData);

						configData = getAdditionalCDDConfig(data, years);
						setConfig(configData);
						break;

					default:
						break;
				}
			}
			setLoading(false);
		})();
	}, [userData, term, scenario, asset, chart, chartName]);

	const onTermChange = (value) => {
		setTerm(value);
		if (value === "Baseline") {
			setScenarioOptions(baselineScenarioOptions);
			setScenario(baselineScenarioOptions[0].value);
		} else {
			setScenarioOptions(projectionScenarioOptions);
			setScenario(projectionScenarioOptions[0].value);
		}
	};

	// const onChange = (dates, dateStrings) => {
	// 	setYearRange(dateStrings);
	// };

	return (
		<section className="text-gray-600  px-4 pb-4">
			<div className="w-full  mx-auto bg-white shadow-lg rounded-md border border-gray-200">
				<div className="p-5">
					<div className="text-lg font-bold my-1">
						Hazard & Vulnerability Charts
					</div>
					<div className="flex justify-between">
						<div className="w-1/5">
							<label className="label font-medium">
								<span>Term</span>
							</label>
							<div
								className={`input input-bordered items-center flex justify-between dark:bg-[#24272F] bg-[#E7EAEE] mb-6 ${
									termOutline
										? "outline outline-2 outline-offset-2 dark:outline-[#3A3C43] outline-[#E7EAEE]"
										: ""
								}`}
							>
								<Selection
									onChange={(value) => {
										onTermChange(value.value);
									}}
									value={term}
									placeholder="Please&nbsp;select"
									options={termOptions}
									focus={setTermOutline}
									showIndicator={false}
								></Selection>
							</div>
						</div>
						<div className="w-1/5">
							<label className="label font-medium">
								<span>Scenario</span>
							</label>
							<div
								className={`input input-bordered items-center flex justify-between dark:bg-[#24272F] bg-[#E7EAEE] mb-6 ${
									scenarioOutline
										? "outline outline-2 outline-offset-2 dark:outline-[#3A3C43] outline-[#E7EAEE]"
										: ""
								}`}
							>
								<Selection
									onChange={(value) => {
										setScenario(value.value);
									}}
									value={scenario}
									placeholder="Please&nbsp;select"
									options={scenarioOptions}
									focus={setScenarioOutline}
									showIndicator={false}
								></Selection>
							</div>
						</div>
						<div className="w-1/5">
							<label className="label font-medium">
								<span>Asset</span>
							</label>
							<div
								className={`input input-bordered items-center flex justify-between dark:bg-[#24272F] bg-[#E7EAEE] mb-6 ${
									assetOutline
										? "outline outline-2 outline-offset-2 dark:outline-[#3A3C43] outline-[#E7EAEE]"
										: ""
								}`}
							>
								<Selection
									onChange={(value) => {
										setAsset(value.value);
										setAssetName(value.label);
										setLocation(value.location);
									}}
									value={asset}
									placeholder="Please&nbsp;select"
									options={assetOptions}
									focus={setAssetOutline}
									showIndicator={false}
								></Selection>
							</div>
						</div>
						<div className="w-1/5">
							<label className="label font-medium">
								<span>Charts</span>
							</label>
							<div
								className={`input input-bordered items-center flex justify-between dark:bg-[#24272F] bg-[#E7EAEE] mb-6 ${
									chartsOutline
										? "outline outline-2 outline-offset-2 dark:outline-[#3A3C43] outline-[#E7EAEE]"
										: ""
								}`}
							>
								<Selection
									onChange={(value) => {
										setChart(value.value);
										setIndexName(value.indexName);
										setChartName(value.label);
										setLegends(value?.legend);
										if (
											value.indexName ===
												"Productivity Loss" ||
											value.indexName === "Additional CDD"
										) {
											setTermOptions(sspTermOptions);
											setTerm(sspTermOptions[0].value);
											setScenarioOptions(
												projectionScenarioOptions
											);
											if (
												scenario !== "ssp245" &&
												scenario !== "ssp585"
											) {
												setScenario(
													projectionScenarioOptions[0]
														.value
												);
											}
										} else {
											setTermOptions(defaultTermOptions);
										}
									}}
									value={chart}
									placeholder="Please&nbsp;select"
									options={chartsOptions}
									focus={setChartsOutline}
									showIndicator={false}
								></Selection>
							</div>
						</div>
					</div>
					<div className="flex w-full flex-row-reverse p-2">
						{/* <YearRangePicker
								onChange={onChange}
								startDate={startDate}
								endDate={endDate}
							/> */}
					</div>
					{status === "success" ? (
						<div className="overflow-hidden">
							<div className="p-6 w-full -mt-12">
								<LineChart
									data={config}
									layout={layout}
									chartName={`${title}_${userData?.email}`}
								/>
							</div>
							<div className="text-sm px-6 pb-4">
								{legends &&
									legends.map((legend) => (
										<div className="flex gap-3">
											<p className="font-medium">
												{legend?.title}
											</p>
											<p>{legend?.body}</p>
										</div>
									))}
							</div>
						</div>
					) : (
						<div className="text-neutral-500 text-lg">
							No chart data found
						</div>
					)}
				</div>
			</div>
		</section>
	);
};

export default HazardVulnerabilityCharts;
