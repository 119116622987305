import React, { useContext, useEffect, useState } from "react";
import ProjectedClimateRiskTable from "./Tables/ProjectedClimateRiskTable";
import Map from "../../../components/Map/Map";
import {
	getRiskColor,
	calculateAndAddMaxPerilProjected,
} from "../../../services/Helpers/climateRiskHelper";
import { getLocationsFromAssets } from "../../../services/Helpers/locationHelper";
import { LoginContext } from "../../../LoginProvider/LoginProvider";
import {
	getFutureClimateRiskPortfolio,
	getFutureClimateRiskScoreAssetWise,
} from "../../../services/ApiCalls/climateRiskCall";
import Selection from "../../../components/Inputs/Selection";

const PhysicalClimateRiskScoreProjected = ({
	setLoading,
	projectedTableScenario,
}) => {
	const [projectedTableData, setProjectedTableData] = useState();
	const [portfolio, setPortfolio] = useState();
	const [locations, setLocations] = useState();
	const [chartModal, setChartModal] = useState(false);
	const [asset, setAsset] = useState();
	const [assetOptions, setAssetOptions] = useState([]);
	const [outline, setOutline] = useState(false);
	const { userData } = useContext(LoginContext);

	useEffect(() => {
		if (userData?.assets) {
			const assetData = userData?.assets.map((asset) => {
				return {
					label: asset?.["Asset Name"],
					value: `${asset?.Lat},${asset?.Long}`,
				};
			});
			setAssetOptions(assetData);
			setAsset(assetData[0].value);
		}
	}, [userData]);

	useEffect(() => {
		(async () => {
			setLoading(true);
			const projectedClimateRiskData =
				await getFutureClimateRiskPortfolio(
					userData?.id,
					projectedTableScenario
				);
			if (projectedClimateRiskData?.status === "success") {
				const data = projectedClimateRiskData.data;
				const portfolioData = data?.portfolio_data;
				const maxRisks = data?.max_risk;
				if (userData?.assets) {
					const locationsData = getLocationsFromAssets(
						userData?.assets,
						maxRisks
					);
					setLocations(locationsData);
				}
				setPortfolio(portfolioData);
			}
		})();
	}, [userData, projectedTableScenario]);

	useEffect(() => {
		(async () => {
			if (asset) {
				setLoading(true);
				const projectedClimateRiskData =
					await getFutureClimateRiskScoreAssetWise(
						userData?.id,
						asset,
						projectedTableScenario
					);
				if (projectedClimateRiskData?.status === "success") {
					let score = projectedClimateRiskData.data;
					score = calculateAndAddMaxPerilProjected(score);
					setProjectedTableData(score);
				}
			}
			setLoading(false);
		})();
	}, [asset, projectedTableScenario]);

	return (
		<div>
			<section className="text-gray-600 px-4 pb-4">
				<div className="w-full  mx-auto bg-white shadow-lg rounded-md border border-gray-200">
					<div className="p-5">
						<div className="flex justify-center mt-4">
							<div className="h-[400px] w-full bg-white shadow-lg rounded-md border border-gray-200 my-4 mb-8 text-center relative">
								<Map data={locations} />
							</div>
						</div>
						<div className="text-lg font-bold">
							Portfolio Heat Map
						</div>
						<div className="mb-2">
							Exposure weighted average of individual asset score
							in the portfolio
						</div>
						<div className="overflow-x-auto">
							{portfolio ? (
								<ProjectedClimateRiskTable
									location={portfolio}
									getRiskColor={getRiskColor}
								/>
							) : (
								<div className="text-neutral-500 text-lg my-4">
									No portfolio data found
								</div>
							)}
							<div className="mt-2">
								<sup className="font-bold text-sm">*</sup>
								Climate change scenarios considered - SSP3-7.0
								and SSP5-8.5
							</div>
							<div className="text-lg font-bold mt-8 mb-0">
								Asset-wise Hazard Score
							</div>
							<div className="flex gap-2 w-full items-center mt-2">
								<div className="font-medium uppercase">
									Asset:
								</div>
								<div className="font-normal normal-case w-full">
									<div
										className={`w-1/5 input input-bordered items-center flex justify-between dark:bg-[#24272F] bg-[#E7EAEE] ${
											outline
												? "outline outline-2 outline-offset-2 dark:outline-[#3A3C43] outline-[#E7EAEE]"
												: ""
										}`}
									>
										<Selection
											onChange={(value) => {
												setAsset(value.value);
											}}
											value={asset}
											placeholder="Please&nbsp;select"
											options={assetOptions}
											focus={setOutline}
											showIndicator={false}
											customClassName="z-[15]"
										></Selection>
									</div>
								</div>
							</div>
							{projectedTableData ? (
								<div className="py-4">
									<ProjectedClimateRiskTable
										location={projectedTableData}
										getRiskColor={getRiskColor}
										setChartModal={setChartModal}
									/>
								</div>
							) : (
								<div className="text-neutral-500 text-lg py-4">
									No data found
								</div>
							)}
							<br />
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default PhysicalClimateRiskScoreProjected;
