import React, { useState } from "react";
import Selection from "../../components/Inputs/Selection";
import Loader from "../../components/Loading/Loader";
import HazardVulnerabilityCharts from "./components/HazardVulnerabilityCharts";
import FinancialMetricsCharts from "./components/FinancialMetricsCharts";
import { analyticsOptions } from "../../constants/analytics";

const Analytics = () => {
	const [loading, setLoading] = useState(false);
	const [analytics, setAnalytics] = useState(analyticsOptions[0].value);
	const [analyticsOutline, setAnalyticsOutline] = useState(false);

	return (
		<div className={`mb-8 -mx-4 ${loading ? "relative h-[80vh]" : ""}`}>
			{loading && <Loader />}
			<div className={`${loading ? "blur-sm" : ""}`}>
				<div className="flex items-center gap-4 mb-2 pl-2 md:pl-1 lg:pl-2 xl:pl-2 sticky top-0 z-20 bg-[#f7f8f9]">
					<h2 className="text-left text-2xl lg:mb-4 xl:mb-4 xl:pt-1 pl-2 font-semibold">
						Analytics
					</h2>
					<div
						className={`w-1/5 lg:mb-4 xl:mb-4 pl-2 input input-bordered items-center flex justify-between dark:bg-[#24272F] bg-[#E7EAEE] mb-6 ${
							analyticsOutline
								? "outline outline-2 outline-offset-2 dark:outline-[#3A3C43] outline-[#E7EAEE]"
								: ""
						}`}
					>
						<Selection
							customClassName="z-20"
							onChange={(value) => {
								setAnalytics(value.value);
							}}
							value={analytics}
							placeholder="Please&nbsp;select"
							options={analyticsOptions}
							focus={setAnalyticsOutline}
							showIndicator={false}
						></Selection>
					</div>
				</div>
				{analytics ? (
					<FinancialMetricsCharts setLoading={setLoading} />
				) : (
					<HazardVulnerabilityCharts setLoading={setLoading} />
				)}
			</div>
		</div>
	);
};

export default Analytics;
