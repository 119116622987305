import React, { useState, useEffect, useContext } from "react";
import { LoginContext } from "../../../LoginProvider/LoginProvider";
import Selection from "../../../components/Inputs/Selection";
import { projectionScenarioOptions } from "../../../constants/analytics";
import { getFinancialImpactChartData } from "../../../services/ApiCalls/climateRiskCall";
import LineChart from "../../../components/Chart/LineChart";
import {
	getChartLayout,
	getFinancialMetricsConfig,
} from "../../../services/Helpers/assetConfig";
import { climateHazardOptions } from "../../../constants/climatefinancialImpact";

const FinancialMetricsCharts = ({ setLoading }) => {
	const [status, setStatus] = useState("");
	const [resultData, setResultData] = useState();
	const [config, setConfig] = useState([]);
	const [layout, setLayout] = useState({});

	const [levelOptions, setLevelOptions] = useState([]);
	const [climateHazard, setClimateHazard] = useState(
		climateHazardOptions[0].value
	);
	const [scenario, setScenario] = useState(
		projectionScenarioOptions[0].value
	);
	const [level, setLevel] = useState();

	const [climateHazardOutline, setClimateHazardOutline] = useState(false);
	const [scenarioOutline, setScenarioOutline] = useState(false);
	const [levelOutline, setLevelOutline] = useState(false);
	const [showPercentage, setShowPercentage] = useState(true);
	const { userData } = useContext(LoginContext);
	useEffect(() => {
		if (userData?.assets) {
			const assetData = userData?.assets.map((asset) => {
				return {
					label: asset?.["Asset Name"],
					value: asset?.["Asset Name"],
				};
			});
			assetData.unshift({
				label: "Portfolio",
				value: "Portfolio",
			});
			setLevelOptions(assetData);
			setLevel(assetData?.[0]?.value);
		}
	}, [userData]);

	useEffect(() => {
		(async () => {
			setLoading(true);
			const result = await getFinancialImpactChartData(
				userData?.id,
				scenario,
				level,
				climateHazard
			);
			setStatus(result?.status);
			if (result?.status === "success") {
				const data = result?.data;
				setResultData(data);
				updateData(data);
			}
			setLoading(false);
		})();
	}, [userData, scenario, level, climateHazard]);

	useEffect(() => {
		if (resultData) {
			updateData(resultData);
		}
	}, [showPercentage]);

	const updateData = (data) => {
		const assetDamageRatio = data["Asset Damage Ratio (%)"];
		const assetDamageValue = data["Asset Damage Value (INR)"];
		const businessInterruptionRatio =
			data["Business Interruption Ratio (%)"];
		const businessInterruptionValue =
			data["Business Interruption Value (INR)"];
		const climateAnnualExpectedLossesRatio =
			data["Climate Annual Expected Losses (%)"];
		const climateAnnualExpectedLossesValue =
			data["Climate Annual Expected Losses (INR)"];
		const years = data["Time period"];
		const layoutData = getChartLayout(
			"Financial Metrics Chart",
			"Years",
			`Financial metrics (${showPercentage ? "%" : "INR"})`
		);
		setLayout(layoutData);
		let configData;
		if (showPercentage) {
			configData = getFinancialMetricsConfig(
				assetDamageRatio,
				businessInterruptionRatio,
				climateAnnualExpectedLossesRatio,
				years
			);
		} else {
			configData = getFinancialMetricsConfig(
				assetDamageValue,
				businessInterruptionValue,
				climateAnnualExpectedLossesValue,
				years
			);
		}
		setConfig(configData);
	};

	return (
		<section className="text-gray-600  px-4 pb-4">
			<div className="w-full  mx-auto bg-white shadow-lg rounded-md border border-gray-200">
				<div className="p-5">
					<div className="text-lg font-bold my-1">
						Financial Metrics Charts
					</div>
					<div className="flex justify-between">
						<div className="w-1/5">
							<label className="label font-medium">
								<span>Scenario</span>
							</label>
							<div
								className={`input input-bordered items-center flex justify-between dark:bg-[#24272F] bg-[#E7EAEE] mb-6 ${
									scenarioOutline
										? "outline outline-2 outline-offset-2 dark:outline-[#3A3C43] outline-[#E7EAEE]"
										: ""
								}`}
							>
								<Selection
									onChange={(value) => {
										setScenario(value.value);
									}}
									value={scenario}
									placeholder="Please&nbsp;select"
									options={projectionScenarioOptions}
									focus={setScenarioOutline}
									showIndicator={false}
								></Selection>
							</div>
						</div>
						<div className="w-1/5">
							<label className="label font-medium">
								<span>Level</span>
							</label>
							<div
								className={`input input-bordered items-center flex justify-between dark:bg-[#24272F] bg-[#E7EAEE] mb-6 ${
									levelOutline
										? "outline outline-2 outline-offset-2 dark:outline-[#3A3C43] outline-[#E7EAEE]"
										: ""
								}`}
							>
								<Selection
									onChange={(value) => {
										setLevel(value.value);
									}}
									value={level}
									placeholder="Please&nbsp;select"
									options={levelOptions}
									focus={setLevelOutline}
									showIndicator={false}
								></Selection>
							</div>
						</div>
						<div className="w-1/5">
							<label className="label font-medium">
								<span>Hazards</span>
							</label>
							<div
								className={`input input-bordered items-center flex justify-between dark:bg-[#24272F] bg-[#E7EAEE] mb-6 ${
									climateHazardOutline
										? "outline outline-2 outline-offset-2 dark:outline-[#3A3C43] outline-[#E7EAEE]"
										: ""
								}`}
							>
								<Selection
									customClassName="z-20"
									onChange={(value) => {
										setClimateHazard(value.value);
									}}
									value={climateHazard}
									placeholder="Please&nbsp;select"
									options={climateHazardOptions}
									focus={setClimateHazardOutline}
									showIndicator={false}
								></Selection>
							</div>
						</div>
						<div className="w-1/6 flex justify-end items-center">
							<label className="flex items-center cursor-pointer">
								<span className="mr-2">&#8377;</span>
								<input
									type="checkbox"
									className="toggle border-blue-500 bg-blue-500 hover:bg-blue-700"
									checked={showPercentage}
									onChange={() =>
										setShowPercentage((prev) => !prev)
									}
								/>
								<span className="ml-2">%</span>
							</label>
						</div>
					</div>
					{status === "success" ? (
						<div className="overflow-hidden">
							<div className="p-6 w-full -mt-12">
								<LineChart
									data={config}
									layout={layout}
									chartName="Financial Metrics Chart"
								/>
							</div>
						</div>
					) : (
						<div className="text-neutral-500 text-lg">
							No chart data found
						</div>
					)}
				</div>
			</div>
		</section>
	);
};

export default FinancialMetricsCharts;
